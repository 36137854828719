// components
import { infosMetrolabODsee } from 'pages/Project/Content/MetrolabODSee'
import { infosMichelinDDI } from 'pages/Project/Content/MichelinDDI'
// import { infosEmbibeKGPrototype } from 'pages/Project/Content/EmbibeKGPrototype'
import { infosSwisscomMobilityInsights } from 'pages/Project/Content/SwisscomMobilityInsights'
import { infosSonyRomi } from 'pages/Project/Content/SonyRomi'
import { infosOrangeHall } from 'pages/Project/Content/OrangeHall'
import { infosSaintGobainAnalyzer } from 'pages/Project/Content/SaintGobainAnalyzer'
import { infosBloomOutilSaas } from 'pages/Project/Content/BloomOutilSaas'
import { infosMalakoffMedericBarometre } from 'pages/Project/Content/MalakoffMedericBarometre'
import { infosDVCartographieCompetences } from 'pages/Project/Content/DVCartographieCompetences'
import { infosDVCompagnon } from 'pages/Project/Content/DVCompagnon'
import { infosCarrefourCartoConso } from 'pages/Project/Content/CarrefourCartoConso'
import { infosSNCFLNPN } from 'pages/Project/Content/SNCFLNPN'
import { infosSidLeeWatchDog } from 'pages/Project/Content/SidLeeWatchDog'
import { infosEDFMaquettePedagogique } from 'pages/Project/Content/EDFMaquettePedagogique'
import { infosDigimindSuggest } from 'pages/Project/Content/DigimindSuggest'
import { infosCiteSciencesJeuVideo } from 'pages/Project/Content/CiteSciencesJeuVideo'

const projects = [
  infosMichelinDDI,
  // infosEmbibeKGPrototype,
  infosSwisscomMobilityInsights,
  infosSonyRomi,
  infosBloomOutilSaas,
  infosOrangeHall,
  infosSaintGobainAnalyzer,
  infosMalakoffMedericBarometre,
  infosCarrefourCartoConso,
  infosMetrolabODsee,
  infosSidLeeWatchDog,
  infosSNCFLNPN,
  infosDVCompagnon,
  infosDVCartographieCompetences,
  infosEDFMaquettePedagogique,
  infosCiteSciencesJeuVideo,
  infosDigimindSuggest
]

export default projects
