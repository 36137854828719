import React from 'react'
import { useParams } from 'react-router-dom'
import { find } from 'lodash'
import { useIntl } from 'react-intl'

import ROUTES from 'common/routes'
import PageHeader from 'components/PageHeader'
import layout from 'common/configs/layout'
import Metas from 'components/Metas'
import jobs from 'pages/Jobs/jobs'
import GridContainer from 'components/GridContainer'

// Common template for a single job page
const Job = () => {
  const { jobSlug, lang } = useParams()
  const job = find(jobs, j => j.slug === jobSlug)
  const { messages } = useIntl()
  return (
    <GridContainer>
      <Metas
        wording='job'
        values={{
          LABEL: job.label[lang]
        }}
      />
      <PageHeader
        isDarkTheme={layout.JOB.isHeaderDark}
        backTo={`/${lang}/${ROUTES.JOBS.SLUG_INDEX}`}
        backToLabel={messages['back-to-jobs']}
        titleMain={job.label[lang]}
      />
      <job.component />
    </GridContainer>
  )
}

export default Job
