// import LeadDesigner from 'pages/Job/LeadDesigner'

const jobs = [
  // {
  //   slug: 'senior-designer-data-product-designer',
  //   component: LeadDesigner,
  //   label: {
  //     fr: 'Senior designer - data product designer',
  //     en: 'Senior designer - data product designer'
  //   },
  //   location: 'Paris',
  //   contract: {
  //     fr: 'CDI',
  //     en: 'Permanent contract'
  //   },
  //   intro: {
  //     fr: 'Vous concevez, prototypez et designez les produits et les interfaces dont Dataveyes a la responsabilité. Vous trouvez des solutions d’expérience utilisateur élégantes pour résoudre des problèmes complexes liés aux nouveaux usages des données.',
  //     en: 'You will conceive, prototype and design products and interfaces for Dataveyes and our clients. You find elegant user experience solutions to solve complex problems regarding new uses of data.'
  //   }
  // }
]

export default jobs
