import { IntlProvider } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { compose, withProps } from 'recompose'

import messagesFr from 'common/wording/fr.json'
import messagesEn from 'common/wording/en.json'

const messages = {
  fr: messagesFr,
  en: messagesEn
}

export default compose(
  withRouter,
  withProps(props => {
    const {
      params: { lang }
    } = props.match
    return { locale: lang, messages: messages[lang] }
  })
)(IntlProvider)
