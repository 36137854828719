const LANG = {
  EN: {
    LABEL: 'English',
    SHORT: 'EN',
    SLUG: 'en'
  },
  FR: {
    LABEL: 'French',
    SHORT: 'FR',
    SLUG: 'fr'
  }
}

export default LANG
