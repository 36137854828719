import React from 'react'

function Close () {
  return (
    <svg width='30' height='30' shapeRendering='geometricPrecision' textRendering='geometricPrecision' viewBox='0 0 20 20'>
      <path fill='#FFF' d='M10.4,9C10.4,9,9,10.4,9,10.4C9,10.4,-1.7,-0.30000000000000004,-1.7,-0.3C-1.7,-0.30000000000000004,-0.30000000000000004,-1.7,-0.3,-1.7C-0.30000000000000004,-1.7,10.4,9,10.4,9Z' transform='translate(2.00002,2) translate(-2.05155e-05,0)' />
      <path fill='#FFF' d='M10.4,9C10.4,9,9,10.4,9,10.4C9,10.4,-1.7,-0.30000000000000004,-1.7,-0.3C-1.7,-0.30000000000000004,-0.30000000000000004,-1.7,-0.3,-1.7C-0.30000000000000004,-1.7,10.4,9,10.4,9Z' transform='translate(18,2) rotate(90) translate(-2.05155e-05,0)' />
      <path fill='#FFF' d='M10.4,9C10.4,9,9,10.4,9,10.4C9,10.4,-1.7,-0.30000000000000004,-1.7,-0.3C-1.7,-0.30000000000000004,-0.30000000000000004,-1.7,-0.3,-1.7C-0.30000000000000004,-1.7,10.4,9,10.4,9Z' fillRule='nonzero' strokeDashoffset='20' transform='translate(18,18) rotate(180) translate(-2.05155e-05,0)' />
      <path fill='#FFF' d='M10.4,9C10.4,9,9,10.4,9,10.4C9,10.4,-1.7,-0.30000000000000004,-1.7,-0.3C-1.7,-0.30000000000000004,-0.30000000000000004,-1.7,-0.3,-1.7C-0.30000000000000004,-1.7,10.4,9,10.4,9Z' transform='translate(1.99998,18) rotate(270) translate(-2.05155e-05,0)' />
    </svg>
  )
}

export default Close
